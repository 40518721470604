*, *::after, *::before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
  margin: 0;
  padding: 0;
  border: 0;
}
html {
  box-sizing: border-box;
}
body {
  background-color: var(--color-bg, white);
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
button, input, textarea, select {
  margin: 0;
}
.btn, .form-control, .link, .reset { // reset style of buttons + form controls
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}
select.form-control::-ms-expand {
  display: none; // hide Select default icon on IE
}
textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}
input::-ms-clear {
  display: none; // hide X icon in IE and Edge
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img, video, svg {
  max-width: 100%;
}
