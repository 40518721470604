.base-material-7days {
    &__slider-top {

    }

    &__slider-page{
        margin: 0 -20px;

        .slick-slide{
            padding: 0 20px;

        }

        .slick-dots {
            display: flex;
            text-align: center;
            margin-top: 26px;
            justify-content: center;


            li {
                text-align: center;
                display: inline-block;
                text-indent: -9999px;
                width: 10px;
                height: 10px;
                cursor: pointer;
                margin-left: 10px;
                background: #C4C4C4;
                border-radius: 50px;

                &.slick-active {
                    background: #DC001D
                }
            }
        }
    }

    &__banners-material {
        @include mBottom(30px, 80px, 375px, 1600px, 80px);
        @include mTop(30px, 70px, 375px, 1600px, 70px);

        img {
            max-width: 100%;
            height: auto;
            display: block;
        }

        @include max-width-container {
            display: none;
        }
    }


    &__title {
        @include fluidFontSize(24px, 36px, 375px, 1600px, 36px);
        @include fluidLineH(36px, 53px, 375px, 1600px, 53px);
        @include mBottom(30px, 40px, 375px, 1600px, 40px);
        @include pTop(15px, 40px, 375px, 1600px, 40px);
        font-weight: 500;
        text-transform: uppercase;

        &.no-padding {
            padding-top: 0;
        }




        &.lg {
            @include mBottom(20px, 30px, 375px, 1600px, 30px);
        }

        &.md {
            @include fluidFontSize(18px, 26px, 375px, 1600px, 26px);
        }

        &.border {
            border-bottom: 1px solid $colorGrayBorder;
            padding-bottom: 20px;
        }

        a {
            display: inline-block;
            color: $colorBase;
            text-decoration: none;
        }

        &.center {
            text-align: center;
            display: flex;
            justify-content: center;
        }

        &.color-white {
            color: $colorWhite;

            a {
                color: $colorWhite;
            }
        }

        &.left {
            text-align: left;
        }

        &.no-mrg {
            margin-top: 0;
        }
    }

    &__item {
        position: relative;

        &.page-index {
            @include max-width-container {
                display: grid;
                grid-template-columns: 250px auto;

                @include max-width-mobile-phone-lg {
                    grid-template-columns: 115px auto;
                }
                @include max-width-container {

                }
            }

            .base-material-7days {
                &__item {
                    &_href {
                        @include max-width-container {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 120%;
                            margin-right: 0;
                        }
                    }

                    &_info {
                        @include max-width-container {
                            margin-right: 0;
                        }
                    }

                    &_rubric {
                        @include max-width-container {
                            margin-right: 0;
                            margin-bottom: 0;
                        }
                    }

                    &_profile {
                        @include max-width-container {
                            height: 33px;
                            padding-right: 12px;
                            top: 11.67px;
                            min-width: 33px;
                            background: #DC001D;
                            border-radius: 0px 50px 50px 0px;
                            span {
                                padding-left: 8px;
                            }
                        }
                    }

                    &_chat {
                        @include max-width-container {
                            height: 33px;
                            padding-right: 12px;
                            top: 11.67px;
                            min-width: 33px;
                            background: rgba(0, 0, 0, 0.5);
                            border-radius: 0px 50px 50px 0px;
                            span {
                                padding-left: 8px;
                            }
                        }
                    }
                }
            }
        }

        &.back {
            background: #F2F2F2;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            .base-material-7days__item_href {
                margin-bottom: 30px;
            }
        }

        &_profile {

            border-radius: 0px 50px 50px 0px;
            position: absolute;
            z-index: 2;
            min-width: 45px;
            padding-right: 15px;
            height: 40px;
            left: 0;
            top: 20px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 110%;

            background: #DC001D url("../images/profile.svg") no-repeat 10px center;
            /* or 15px */

            color: #FFFFFF;

            span {
                padding-left: 30px;
            }

        }

        &_chat {

            border-radius: 0px 50px 50px 0px;
            position: absolute;
            z-index: 2;
            min-width: 45px;
            padding-right: 15px;
            height: 40px;
            left: 0;
            top: 20px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 110%;

            background: rgba(0, 0, 0, 0.5) url("../images/chat-teardrop.svg") no-repeat 10px center;
            /* or 15px */

            color: #FFFFFF;

            span {
                padding-left: 30px;
            }

        }

        &_content-box {
            padding: 20px 10px 28px 10px;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &_img {
            position: relative;
            margin-bottom: 20px;

            &.no-mrg {
                margin-bottom: 0;
            }

            img {
                display: block;
                position: relative;
                z-index: 1;
                border-radius: 10px;
                @include max-width-container {
                    width: 100% !important;
                    max-width: 100% !important;
                    height: auto !important;
                }

            }
        }

        &_info {
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
        }

        &_clock {
            background: url("../images/clock.svg") no-repeat left center;
            padding-left: 15px;
            margin-left: 10px;
        }

        &_rubric-other {
            text-decoration: none;
            color: $colorGray;
            margin-left: 10px;

            &:before {
                background: #DC001D;
                width: 5px;
                height: 5px;
                border-radius: 50px;
                content: "";
                vertical-align: top;
                display: inline-block;
                margin-right: 8px;
                margin-top: 5px;

            }

        }

        &_info {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 7px;
            font-family: $font-Roboto;
            /* identical to box height, or 17px */
            /* Gray 3 */
            text-decoration: none;
            color: $colorGray;

        }

        &_rubric {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 7px;
            font-family: $font-Roboto;
            /* identical to box height, or 17px */
            /* Gray 3 */
            text-decoration: none;
            color: $colorGray;
            display: block;
            margin-left: 10px;
            margin-right: 10px;

            a {
                text-decoration: none;
                color: $colorGray;
            }

            &.date {
                margin-top: 15px;
            }

            &.summary {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                position: relative;
                -webkit-box-orient: vertical;
                /*      &:after {
                          content: '';
                          position: absolute;
                          bottom: 0;
                          width: 100%;
                          height: 30px;
                          left: 0;
                          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .7) 50%, #fff 100%);

                      }*/
            }
        }

        &_href {
            font-style: normal;
            font-weight: 300;
            font-size: 22px;
            line-height: 120%;
            text-decoration: none;
            color: $colorBase;
            display: block;
            margin-bottom: 6px;
            font-family: $font-base;
            margin-left: 10px;
            margin-right: 10px;

            a {
                text-decoration: none;
                color: $colorBase;
            }

            &.summary {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                position: relative;


            }
        }

        &_button {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 110%;
            /* or 15px */
            text-align: center;
            text-transform: uppercase;
            color: $colorWhite;
            background: $colorBase;
            border-radius: 20px;
            text-decoration: none;
            display: block;
            padding-top: 11px;
            padding-bottom: 14px;
            margin: 30px auto 0 auto;
            padding-left: 30px;
            padding-right: 30px;
            margin-top: auto;
        }


        &_unpublished-materials {
            position: absolute;
            right: 0;
            top: 0;
            border-top-right-radius: 10px;
            background: #dc001d;
            color: #fff;
            z-index: 2;
            padding: 5px;
        }

    }

    &__now-read {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;
        @include mBottom(40px, 80px, 375px, 1600px, 80px);
        @include max-width-container {
            grid-template-columns: 1fr;
        }
          &-slider{
              padding-right: 20px;
              padding-left: 20px;
          }
            &-container{
                background: #F4F6FF;
                padding-bottom: 40px;
                @include mBottom(30px, 80px, 375px, 1600px, 80px);

                @include max-width-container{
                    padding-bottom: 30px;
                }



                 &.leisure{


                     background: #F8EDED url("../images/bacjk-leisure.png");
                 }
            }
    }






    &__videobox {
        max-width: 984px;
        width: 100%;
        box-sizing: border-box;
        background: #F2F2F2;
        border-radius: 10px;
        overflow: hidden;
        @include mBottom(10px, 40px, 375px, 1600px, 40px);
        padding-bottom: 77px;


        @include max-width-container {
            padding-bottom: 0;
            max-width: inherit;
            width: inherit;
            margin-left: -20px;
            margin-right: -20px;
        }
        @include portrait-laptop {
            margin-left: 0;
            margin-right: 0;
        }

        hr {
            width: 90%;
            margin-right: auto;
            margin-left: auto;
            @include max-width-container {
                display: none;
            }
        }

    }

    &__videobox-slider {
        overflow: hidden;
        box-sizing: border-box;
        padding-left: 50px;
        padding-right: 60px;
        position: relative;
        @include max-width-container {
            padding-right: 0;
            padding-left: 0;
            padding-bottom: 40px;
        }

        .slick-prev {
            background: url("../images/arrow-forward.svg") no-repeat;
            width: 24px;
            height: 24px;
            border: none;
            text-indent: -9999px;
            cursor: pointer;
            position: absolute;
            left: 15px;
            top: 45%;
            transform: rotate(-180deg);
        }

        .slick-next {
            background: url("../images/arrow-forward.svg") no-repeat;
            width: 24px;
            border: none;
            position: absolute;
            right: 15px;
            top: 45%;
            height: 24px;
            text-indent: -9999px;
            cursor: pointer;
        }

        .slick-dots {
            display: block;
            text-align: center;
            margin-top: 30px;

            li {
                text-align: center;
                display: inline-block;
                text-indent: -9999px;
                width: 6px;
                height: 6px;
                cursor: pointer;
                margin-left: 10px;
                background: #C4C4C4;
                border-radius: 50px;

                &.slick-active {
                    background: #DC001D
                }
            }
        }

        &_item {
            display: flex !important;
            position: relative;
            align-items: flex-start !important;
            @include max-width-container {
                display: block !important;
            }

            .base-material-7days__item-done, .base-material-7days__item-del {
                top: 5px;
            }

        }

        &_player {

            position: relative;
            cursor: pointer;

            &:after {
                background: rgba(0, 0, 0, 0.7);
                border-radius: 10px 0 0 10px;
                content: "";
                clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
                height: 100%;
                position: absolute;
                top: 0;
                width: 80px;
                z-index: 2;
            }

            &:before {
                width: 60px;
                height: 60px;
                content: "";
                top: 25%;
                z-index: 3;
                margin-left: -30px;
                left: 35%;
                position: absolute;
                background: rgba(220, 0, 29, 0.5) url("data:image/svg+xml,%3csvg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M17 10L0.499999 19.5263L0.5 0.47372L17 10Z' fill='white'/%3e %3c/svg%3e") no-repeat center center;
                border-radius: 30px;
            }

            @include max-width-container {
                margin: 10px 10px 20px 10px;
            }

            img {
                width: 180px;
                border-radius: 10px;
                height: 120px;
                max-width: inherit !important;
                @include max-width-container {
                    max-width: 100% !important;
                    width: 100%;
                    height: inherit !important;
                }
            }

        }

        &_content {
            margin-left: 20px;

            @include max-width-container {
                margin: 10px;
            }
        }

        &_content-title {
            font-style: normal;
            font-weight: 300;
            font-size: 22px;
            line-height: 120%;
            display: block;
            text-decoration: none;
            color: inherit;
            margin-bottom: 20px;
            max-width: 210px;

            @include max-width-container {
                max-width: 100%;
                box-sizing: border-box;
            }
        }

        &_content-messages {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 110%;
            position: relative;

            &:before {
                content: "";
                background: url("../images/chat-teardrop.svg") no-repeat;
                display: inline-block;

                margin-right: 5px;
                width: 14px;
                height: 14px;

            }
        }

        &_content-logo {

        }
    }

    &__materialbox-index {
        display: grid;
        @include mBottom(20px, 80px, 375px, 1600px, 80px);
        grid-template-columns: 1fr 1fr 1fr;

        gap: 40px;
        align-items: flex-start;
        @include max-width-container {
            gap: 0;
            grid-template-columns: 1fr;
            .base-material-7days__item {

            }
            .base-material-7days__item_info {
                div {
                    display: none;
                }
            }
        }
    }

    &__materialbox {
        display: grid;
        @include mBottom(40px, 80px, 375px, 1600px, 80px);
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 40px 40px;
        align-items: flex-start;

        grid-template-areas:
    "material1 material2 material3"
    "material4 material5 stars-trend"
    "material7 material8 stars-trend";

        .material1 {
            grid-area: material1;
        }

        .material2 {
            grid-area: material2;
        }

        .material3 {
            grid-area: material3;
        }

        .material4 {
            grid-area: material4;
        }

        .material5 {
            grid-area: material5;
        }

        .stars-trend {
            grid-area: stars-trend;
            border: 1px solid $colorGray;
            box-sizing: border-box;
            border-radius: 10px;

        }

        @include max-width-container {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-template-areas:
    "material1"
    "material2"
    "material3"
    "material4"
    "material5"
    "material7"
    "material8"
    "stars-trend"
        }

        .material7 {
            grid-area: material7;
        }

        .material8 {
            grid-area: material8;
        }


        &_stars-trend {
            padding: 27px 30px 30px 30px;

            .autocomplete-search.autoComplete_wrapper {
                width: inherit !important;
            }

        }

        &_stars-title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 30px;
            /* identical to box height */
            text-transform: uppercase;
        }

        &_stars-slider-desctop {

            @include max-width-mobile {
                display: none;
            }
        }

        &_stars-slider-bottom {
            display: none;
            @include max-width-mobile {
                display: block;
            }

            .slick-dots {
                display: block;
                text-align: center;
                margin-top: 0px;

                li {
                    text-align: center;
                    display: inline-block;
                    text-indent: -9999px;
                    width: 6px;
                    height: 6px;
                    cursor: pointer;
                    margin-left: 10px;
                    background: #C4C4C4;
                    border-radius: 50px;

                    &.slick-active {
                        background: #DC001D
                    }
                }
            }
        }

        &_stars-slider-item {
            display: flex !important;
            margin-bottom: 25px;
        }

        &_stars-text-search {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 120%;
            font-family: $font-Roboto;
            margin-bottom: 20px;
        }

        .header-7days__search-autocomplete {
            padding-left: 0;
        }

        .autocomplete-search .autocomplete, .autocomplete-search.autoComplete_wrapper {
            width: inherit;
            padding: 0;
            box-sizing: border-box;
            @include max-width-container {

            }
        }

        .autocomplete {

        }

        &_stars-slider-logo {
            img {
                border-radius: 10px;
                width: 80px;
                max-width: inherit !important;
                height: 80px;
            }

        }

        &_stars-slider-line {
            color: $colorGray;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 120%;
            font-family: $font-Roboto;
        }

        &_stars-slider-content {
            margin-left: 15px;
        }

        &_stars-slider-actor {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 110%;
            text-decoration: none;
            color: $colorBase;
        }

        .stars-form-search {
            display: grid;
            align-items: center;
            justify-content: space-between;
            grid-template-columns: 1fr  40px;

            .autocomplete-search {
                input {
                    padding: 0 10px !important;
                }
            }
        }

        .stars-form-input {
            box-sizing: border-box;
            width: 100% !important;
            border: 1px solid $colorGray;
            height: 40px;
            border-radius: 20px;
            padding-left: 10px;
            padding-right: 10px;

            &:focus {
                outline: none;
            }
        }

    }

    &__health {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;
        @include mBottom(40px, 80px, 375px, 1600px, 80px);
        @include max-width-container {
            grid-template-columns: 1fr;
        }
    }


    &__now-discuss-column {
        border: 1px solid $colorGray;
        border-radius: 10px;
        box-sizing: border-box;
        margin-top: 40px;
        padding: 27px 30px 30px 30px;
        @include max-width-container {
            padding: 20px 20px 20px 20px;
        }

        .base-material-7days__newsbox_logo img {
            width: 70px;
            height: 70px;
        }

        .base-material-7days__newsbox_item {
            margin-bottom: 40px;
        }

        &_title {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            margin-bottom: 30px;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 18px;

            &:before {
                background: $colorBase;
                border-radius: 10px;
                content: "";
                position: absolute;
                width: 100%;
                box-sizing: border-box;
                height: 8px;
                bottom: 0;
            }

        }

    }

    &__now-discuss {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;

        @include max-width-container {
            grid-template-columns: 1fr;
        }
    }

    &__horoscope {


        box-sizing: border-box;


        @include mBottom(30px, 80px, 375px, 1600px, 80px);
        background: #171F2C url("../images/horoscope-new.jpg") no-repeat center center;
        background-size: cover;
        border-radius: 10px;
        padding-bottom: 54px;
        @include max-width-container {
            margin-left: -20px;
            margin-right: -20px;
            border-radius: 0;
            padding-bottom: 30px;
        }

        .base-material-7days__item-done{
        top: 3px;
        }
        .base-material-7days__title{
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            @include pBottom(30px, 30px, 375px, 1600px, 30px);
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 26px;
            @include max-width-container{
                border-bottom: 0;     margin-bottom:0;
            }
        }


        &_slider {
            border-top: 1px solid #3d4752;
            margin-left: 40px;
            margin-right: 40px;
            padding-top: 25px;
            overflow: hidden;
            box-sizing: border-box;
            @include max-width-container {
                margin-right: 0;
                margin-left: 0;
                border-top: 0;
                padding-top: 0px;
            }

            .slick-dots {
                display: block;
                text-align: center;
                margin-top: 30px;

                li {
                    text-align: center;
                    display: inline-block;
                    text-indent: -9999px;
                    width: 6px;
                    height: 6px;
                    cursor: pointer;
                    margin-left: 10px;
                    background: #C4C4C4;
                    border-radius: 50px;

                    &.slick-active {
                        background: #DC001D
                    }
                }
            }
        }

        &_slider-item {
            display: block !important;
            position: relative;

            .base-material-7days__item-done, .base-material-7days__item-del {
                top: 5px;
            }
        }

        &_logo {
            margin-bottom: 24px;
            @include max-width-container {

            }

            img {

                border-radius: 10px;


                @include max-width-mobile {

                }
            }
        }

        &_text {
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 120%;
            text-align: center;
            color: $colorWhite;

            a {
                display: inline-block;
                color: $colorWhite;
                text-decoration: none;
            }
        }
    }

    &__shops {
        max-width: 984px;
        width: 100%;
        box-sizing: border-box;
        @include mBottom(20px, 20px, 375px, 1600px, 20px);

        &_slider-item {

        }

        &_slider-img {
            img {
                width: 120px;
                border-radius: 10px;
                height: 80px;
                max-width: inherit !important;
                @include max-width-container {
                    max-width: 100% !important;
                    width: 100%;
                    height: inherit !important;
                }
            }
        }

        &_slider-page {
            margin-top: 40px;
            @include max-width-mobile {

                margin-top: 0;

            }
        }

        &_slider, &_slider-page {
            overflow: hidden;
            position: relative;
            box-sizing: border-box;
            padding-left: 40px;
            padding-right: 40px;
            @include max-width-container {
                padding-right: 0;
                padding-left: 0;
                margin-left: -20px;
                margin-right: -20px;
                .slick-slide{
                    padding: 0 10px;

                }
            }

            .slick-prev {
                background: url("../images/arrow-forward.svg") no-repeat;
                width: 24px;
                height: 24px;
                border: none;
                text-indent: -9999px;
                cursor: pointer;
                position: absolute;
                left: 10px;
                top: 43%;
                transform: rotate(-180deg);
            }

            .slick-next {
                background: url("../images/arrow-forward.svg") no-repeat;
                width: 24px;
                border: none;
                position: absolute;
                right: 15px;
                top: 43%;
                height: 24px;
                text-indent: -9999px;
                cursor: pointer;
            }

            .slick-dots {
                display: block;
                text-align: center;
                margin-top: 30px;

                li {
                    text-align: center;
                    display: inline-block;
                    text-indent: -9999px;
                    width: 10px;
                    height: 10px;
                    cursor: pointer;
                    margin-left: 10px;
                    background: #C4C4C4;
                    border-radius: 50px;

                    &.slick-active {
                        background: #DC001D
                    }
                }
            }
        }

        &_select {
            display: grid;

            margin-top: 50px;
            grid-gap: 40px;

            grid-template-columns: 1fr 1fr 1fr;
            @include max-width-container {
                display: none;
            }


            &.page {
                grid-template-columns: 1fr 1fr;
                border-bottom: 0;
                padding-bottom: 0;
            }
        }

        &_select-item {
            text-decoration: none;
            color: $colorBase;
            text-align: center;
        }

        &_select-text {
            font-style: normal;
            font-weight: 300;
            font-size: 22px;
            line-height: 120%;
        }

        &_select-logo {
            margin-bottom: 10px;

            img {
                display: block;
                border-radius: 10px;
            }
        }

    }


    &__newsbox {
        background: #F2F2F2;
        border-radius: 10px;
        overflow: hidden;
        @include mBottom(10px, 40px, 375px, 1600px, 40px);
        padding-bottom: 17px;

        &.page-block {
            @include mBottom(50px, 80px, 375px, 1600px, 80px);

        }

        .base-material-7days__item_info {

        }

        @include max-width-container {
            margin-right: -20px;
            margin-left: -20px;
            box-sizing: border-box;
        }

        &_wrapper {
            margin-left: 40px;
            margin-right: 40px;
            border-bottom: 1px solid #E0E0E0;
            display: grid;
            padding-bottom: 30px;
            margin-bottom: 40px;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 40px;
            @include max-width-container {
                grid-template-columns: 1fr;
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        &_item {
            display: flex;
            @include max-width-container {
                display: grid;
                grid-template-columns: 70px auto;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &_item-title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            margin-bottom: 10px;
            text-decoration: none;
            color: $colorBase;
            display: block;
        }

        &_item-block {
            display: block;

            .base-material-7days {
                &__newsbox {
                    &_item-content {
                        margin-left: 0;
                    }

                    &_logo {
                        margin-bottom: 10px;

                        img {
                            width: inherit;
                            height: inherit;
                            @include max-width-container {
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                    }

                    &_comments {

                        @include max-width-container {
                            display: block;
                            margin-top: 10px;
                        }
                    }

                    &_text {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        font-family: $font-Roboto !important;
                        color: $colorGray;
                        margin-left: 0;
                        text-decoration: none;
                    }
                }
            }
        }


        &_comments {
            @extend .base-material-7days__videobox-slider_content-messages;
            @include max-width-container {
                display: none;
            }
        }

        &_text {
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 110%;
            margin-bottom: 15px;
            color: $colorBase;
            text-decoration: none;
            display: inline-block;
            @include max-width-container {
                margin-left: 10px;
                margin-bottom: 0;
            }

        }

        &_item-content {
            margin-left: 15px;
            @include max-width-container {
                margin-left: 0px;
            }

            .base-material-7days__item_info {
                margin-left: 0 !important;
                margin-right: 0 !important;
                @include max-width-container {
                    margin-left: 10px !important;
                    margin-right: 10px !important;
                }
            }
        }

        &_logo {
            position: relative;
            @include max-width-container {
                margin-bottom: 20px;
            }

            img {
                width: 100px;
                border-radius: 10px;
                height: 66px;
                max-width: inherit !important;
                @include max-width-container {
                    max-width: 100% !important;
                    width: 100%;
                    height: auto !important;
                }
            }
        }

        .base-material-7days__item_rubric {
            display: none;
            @include max-width-container {
                display: block;
            }
        }

        .base-material-7days__item_chat {
            display: none;
            @include max-width-container {
                display: flex;
            }
        }

        &_more-news-box {
            text-align: center;
        }

        &_more-news {
            text-align: center;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 120%;
            position: relative;
            /* identical to box height, or 22px */
            text-decoration: none;
            color: #000000;
            padding-bottom: 30px;
            display: inline-block;

            &:before {
                background: url("../images/arrow-forward.svg") no-repeat no-repeat center;
                transform: rotate(90deg);
                position: absolute;
                bottom: 0;
                width: 12px;
                height: 20px;
                left: 50%;
                margin-left: -6px;
                content: "";
            }
        }

    }

    &__newsbox-bottom {
        overflow: hidden;
        @include mBottom(50px, 80px, 375px, 1600px, 80px);

        a {
            color: $colorBase;
            text-decoration: none;
        }


        @include max-width-container {
            margin-right: -20px;
            margin-left: -20px;
            box-sizing: border-box;
        }

        &_wrapper {

            display: grid;

            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 40px;
            @include max-width-container {
                grid-template-columns: 1fr;
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        &_item {

        }


        &_title {
            font-style: normal;
            font-weight: 500;
            margin-bottom: 10px;
            @include fluidFontSize(24px, 30px, 375px, 1600px, 30px);
            @include fluidLineH(36px, 44px, 375px, 1600px, 44px);
            text-transform: uppercase;
            @include max-width-container {
                text-align: center;
                margin-bottom: 20px;
            }


        }

        &_title-lg {

            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 120%;

        }

        &_title-sm {
            border-top: 1px solid #E0E0E0;
            margin-top: 20px;
            padding-top: 20px;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 110%;
            @include max-width-container {
                &:last-child {
                    border-bottom: 1px solid #E0E0E0;
                    padding-bottom: 20px;
                }
            }
        }


        &_logo {
            position: relative;
            margin-bottom: 20px;

            img {
                width: 300px;
                border-radius: 10px;
                height: 200px;
                max-width: inherit !important;
                @include max-width-container {
                    width: 100% !important;
                    max-width: 100% !important;
                    height: auto !important;

                }
            }
        }


    }

    &__reference-information {
        background: $colorRedBold;
        border-radius: 10px;
        font-family: $font-Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        color: $colorWhite;
        /* or 17px */
        text-align: center;
        padding: 30px 20px 30px 20px;
    }

    &__box-monitoring {
        @include mBottom(50px, 80px, 375px, 1600px, 80px);
    }


    &__banners-bottom {
        box-sizing: border-box;
        z-index: 99;
        font-family: $font-base;
        position: relative;
        width: 100%;

        &_logo {
            object-fit: cover;
            width: 100%;
            @include max-width-mobile {
                display: none;
            }
        }

        &_logo-mobile {
            object-fit: cover;
            width: 100%;
            display: none;
            @include max-width-mobile {
                display: block;
            }
        }

        &_box-button {
            position: absolute;
            bottom: 60px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(260px, 260px));
            justify-content: center;
            align-items: center;
            grid-gap: 20px;
            @include max-width-mobile {
                display: block;


            }
        }

        &_button-left {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #d10017;
            color: #fff;
            font-weight: 300;
            font-size: 24px;
            text-decoration: none;
            text-transform: uppercase;
            @include max-width-mobile {
                margin-bottom: 10px;
                height: 40px;
                font-size: 18px;
                background: #b04f46;
            }

            span {
                width: 100%;
                text-align: center;
            }
        }

        &_button-right {
            justify-content: center;
            background: #fff;
            color: #333;
            align-items: center;
            display: flex;
            font-size: 24px;
            text-decoration: none;
            font-weight: 300;
            width: 100%;
            height: 60px;
            text-transform: uppercase;
            @include max-width-mobile {
                height: 40px;
                font-size: 18px;
            }

            span {
                width: 100%;
                text-align: center;
            }
        }
    }

    &__cinema-block {
        @include mBottom(40px, 80px, 375px, 1600px, 80px);

        .video-7days__rating-box {
            top: 0;
            right: -32px;
        }

        .video-7days__rating-box {
            font-size: 30px;
        }

        &_news {
            background: #F2F2F2;
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 30px;
            padding: 35px 40px 12px 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 40px;
            @include max-width-container {
                display: block;
                padding: 25px 30px 12px 30px;
                .base-material-7days__newsbox_item {
                    margin-bottom: 20px;
                }
            }

        }

        &_box {
            display: grid;
            grid-gap: 40px;
            grid-template-columns: 1fr 1fr;
            @include max-width-container {
                display: block;
                .base-material-7days__item {
                    margin-bottom: 40px;
                }
            }
        }

        &_wrapper {
            display: grid;
            grid-gap: 40px;
            grid-template-columns: auto minmax(auto, 301px);
            align-items: flex-start;
            @include max-width-container {
                display: block;
            }
        }


        &_wrapper-news-title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            border-bottom: 1px solid $colorGrayBorder;
            /* identical to box height */
            text-transform: uppercase;
            padding-bottom: 20px;
            margin-bottom: 30px;
            color: $colorBase;

            a {
                color: $colorBase;
                text-decoration: none;
            }
        }

        &_wrapper-news-all {
            border-top: 1px solid $colorGrayBorder;
            padding-top: 30px;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            display: block;
            line-height: 120%;
            position: relative;
            @include max-width-container {
                text-align: center;
            }

            a {
                color: $colorBase;
                text-decoration: none;
            }

            &:after {
                display: inline-block;
                vertical-align: middle;
                content: "";
                width: 12px;
                height: 20px;
                margin-left: 14px;
                background: url("../images/page-index/news-all.svg");
                @include max-width-container {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 14px;
                    transform: rotate(90deg);
                }

            }
        }

        &_wrapper-news {
            background: $back-F2F2F2;
            border-radius: 10px;
            padding: 27px 30px 27px 30px;
            @include max-width-container {
                padding: 30px 20px 20px 20px;
            }

            .base-material-7days {
                &__item {
                    &_info {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                &__newsbox {
                    &_text {
                        margin-bottom: 0;
                        @include max-width-container {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        &_wrapper-news-item {
            @include mBottom(30px, 40px, 375px, 1600px, 40px);
        }

        &_wrapper-preview {
            border: 1px solid $colorGray;
            box-sizing: border-box;
            padding: 27px 30px 30px 30px;
            border-radius: 10px;

            .video-7days {
                &__preview {
                    background: none;
                    border-radius: 0;
                    position: relative;
                    padding: 0;

                    &_box {
                        display: block;
                    }

                }


            }

        }


    }


    &__history{

        &-wrapper{
            @include mBottom(30px, 80px, 375px, 1600px, 80px);
            display: grid;
            grid-template-columns: repeat(3,1fr);

            grid-gap: 40px;
            @include max-width-container{
                grid-template-columns: 1fr;          grid-gap: 30px;
            }
            &-title{
                margin-bottom: 20px;
                color: rgb(51, 51, 51);
                font-size: 22px;
                font-weight: 500;
                line-height: 33px;
                letter-spacing: 0;
                text-align: left;
                text-transform: uppercase;
                a{
                    text-decoration: none;
                    color: rgb(51, 51, 51);
                }
            }

            .material-7days__images_title.base{
                left: 20px;
                right: 20px;
            }
 .health-7days__content{
     padding-top: 20px;
     padding-bottom: 20px;
     border-bottom: 1px solid rgb(224, 224, 224);
 }
            .health-7days__logo_time-box{
                padding: 4px 12px 6px 12px;
                border-radius: 0px 0px 6px 6px;
                background: rgb(220, 0, 29);
                color: rgb(255, 255, 255);
                font-size: 16px;
                font-weight: 300;
                line-height: 120%;
                letter-spacing: 0;
                text-align: left;
                margin-right: 20px;
                text-transform: capitalize;
            }
            .health-7days__content:last-child{
                padding-bottom: 0;
                border-bottom: 0;
            }

           .material-7days__images {
           margin-bottom: 10px;
           }
        .material-7days__images_rubric{
            color: rgb(255, 255, 255);

            font-family:$font-base;

            font-size: 18px;

            font-weight: 300;

            line-height: 110%;

            letter-spacing: 0;
            text-transform: none;
            text-align: left;
        }







        }




    }





    &__page-block {
        @include mBottom(40px, 80px, 375px, 1600px, 80px);




        &_news {
            background: #F2F2F2;
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 30px;
            padding: 35px 40px 12px 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 40px;
            @include max-width-container {
                display: block;
                padding: 25px 30px 12px 30px;
                .base-material-7days__newsbox_item {
                    margin-bottom: 20px;
                }
            }

        }

        &_box {
            display: grid;
            grid-gap: 40px;
            grid-template-columns: 1fr 1fr;
            @include max-width-container {
                display: block;
                .base-material-7days__item {
                    margin-bottom: 40px;
                }
            }
        }

        &_wrapper {
            display: grid;
            grid-gap: 40px;
            grid-template-columns: auto minmax(auto, 301px);
            align-items: flex-start;
            @include max-width-container {
                display: block;
            }
        }

        &_wrapper-news-title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            border-bottom: 1px solid $colorGrayBorder;
            /* identical to box height */
            text-transform: uppercase;
            padding-bottom: 10px;
            margin-bottom: 30px;
            color: $colorBase;

            a {
                color: $colorBase;
                text-decoration: none;
            }

            &.grid{
                display: grid;
                align-items: center;
                justify-content: space-between;
                grid-template-columns: 1fr 1fr;
            }
        }

        &_wrapper-news-title-text{
            text-align: right;
            @include max-width-container{
                text-align: left;
            }
        }

        &_wrapper-news-all {
            color: #828282 !important;
            font-family:$font-Roboto !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 120% !important;
            position: relative;
            text-align: right;
            text-transform: none;
            @include max-width-container {
                text-align: center;
            }

            a {
                color: $colorBase;
                text-decoration: none;
            }
   &.desctop-visible{
       @include max-width-container{
           display: none;

       }

   }
            &.mobile-visible{
                display: none;
                @include max-width-container{
                    display: block;
                    text-align: left;
                    margin-top: 30px;
                }

            }
        }
        &_wrapper-news-grid{
            display: grid;
            grid-gap: 30px;
            justify-content: space-between;
            grid-template-columns: repeat(4,1fr);

            @include max-width-container{
                grid-template-columns:1fr;
            }

        }
        &_wrapper-news {
            border: 1px solid rgb(130, 130, 130);
            border-radius: 10px;
            padding: 30px 30px 40px 30px;
            margin-bottom: 40px;
            @include max-width-container {
                padding: 30px 20px 20px 20px;
            }

            .base-material-7days {
                &__item {
                    &_info {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                &__newsbox {
                    &_text {
                        margin-bottom: 0;
                        text-transform:  none;
                        @include max-width-container {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        &_wrapper-news-item {

            position: relative;
            .base-material-7days__item-del{
                top: -3px;
                left: -27px;
            }
        }



    }





    &__stars-trend-index {
        @include mBottom(0, 30px, 375px, 1600px, 30px);
        border-top: 1px solid rgb(224, 224, 224);
        border-bottom: 1px solid rgb(224, 224, 224);

        @include pTop(30px, 70px, 375px, 1600px, 70px);
        @include pBottom(30px, 80px, 375px, 1600px, 80px);



        @include max-width-container {

            margin-left: -20px;
            margin-bottom: 15px;
            margin-right: -20px;
        }

        &_title {
            text-align: center;
            font-style: normal;
            font-weight: 500;
            @include fluidLineH(36px, 53px, 375px, 1600px, 53px);
            @include fluidFontSize(24px, 36px, 375px, 1600px, 36px);
            text-transform: uppercase;

        }

        &_wrapper-item {
            flex-shrink: 0;
            @include max-width-container {
                max-width: 80px;
            }

        }

        &_wrapper {
            padding: 40px 52px;
            display: grid;
            background: $back-F2F2F2;
            border-radius: 10px;
       margin-top: 30px;
            grid-template-columns:  repeat(7,1fr);
            justify-content: center;


            gap: 30px;


            overflow-x: auto;
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            scroll-padding: 1rem;
            scroll-snap-align: start;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            @include max-width-container {
                padding-left: 20px;
                display: flex;

                justify-content: space-between;
                gap: 20px;
                padding-right: 20px;

            }


            @include max-width-container {
                padding-left: 0;
                padding-right: 0;
                padding-top: 30px;
            }


        }

        &_logo {
            display: block;
            margin-bottom: 8px;
            flex-shrink: 0;

            img {
                border-radius: 500px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                @include max-width-container {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        &_name {
            font-style: normal;
            font-weight: 300;
            @include fluidFontSize(16px, 18px, 375px, 1600px, 18px);
            line-height: 100%;
            color: $colorBlack;
            text-align: center;
            display: block;
            text-decoration: none;
        }

        &_all-stars {
            width: 100px;
            background: #E0E0E0;
            height: 100px;
            display: flex;

            border-radius: 500px;
            align-items: center;
            font-style: normal;
            font-weight: 300;
            @include fluidFontSize(16px, 18px, 375px, 1600px, 18px);
            line-height: 100%;
            flex-shrink: 0;
            text-decoration: none;
            color: $colorBase;
            justify-content: center;
            @include max-width-container {
                width: 80px;
                height: 80px;
            }
        }

        &_form {
            display: flex;
            justify-content: center;
            padding-top: 30px;

            .stars-form-input {
                width: 300px;
                @include max-width-container {
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }

    }


    &__stars-trend-bottom {
        border: 1px solid $colorGray;
        border-radius: 10px;

        box-sizing: border-box;
        padding: 40px 30px 15px 30px;

        @include max-width-container {
            padding: 40px 30px 40px 30px;
        }

        &_wrapper {
            display: grid;
            grid-template-columns: minmax(auto, 272px) minmax(auto, 272px);
            grid-gap: 40px;

            @include max-width-container {
                display: block;
            }
        }

        .header-7days__search-autocomplete_button {
            margin-left: 10px;
            @include max-width-container {
                width: 48px !important;
            }

        }

        .stars-form-search {
            align-items: center;
            display: flex;
            justify-content: space-between;


        }

        .stars-form-input {
            border: 1px solid $colorGray;
            border-radius: 20px;
            box-sizing: border-box;
            height: 40px;
            padding-left: 10px;
            padding-right: 10px;
            width: 224px !important;
            @include max-width-container {
                width: 100% !important;
            }
        }

    }

    &__tags {
        display: flex;
        margin-bottom: -20px;
        white-space: nowrap;
        margin-top: 50px;
        align-items: center;

        .material-7days__tags_item {
            margin-left: 0;
            margin-bottom: 0;
            margin-right: 20px;
        }

        @include max-width-container {
            margin-top: 40px;
        }

        &.wrapper-mobile-scroll {
            cursor: grab;
            -ms-overflow-style: none; /* IE 10+ */
            scrollbar-width: none; /* Firefox */
            max-width: 100%;
            overflow-x: scroll;

            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar { /* chrome based */
                width: 0px; /* ширина scrollbar'a */
                height: 0;
                background: transparent; /* опционально */
            }
        }

    }

    &__player-fixed-box {
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 100;
        width: 300px;
        font-family: $font-base;

        &_preview {
            padding: 10px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            cursor: pointer;
            display: grid;
            position: absolute;
            bottom: 0;
            right: 0;
            grid-template-columns: 30px 1fr;
            align-items: center;
            line-height: 100%;
            grid-gap: 10px;
            opacity: 1;
            /* Gray 5 */
            background: #E0E0E0;
            border: 1px solid #BDBDBD;
            box-sizing: border-box;
            border-radius: 10px;

        }

        &_full {
            padding: 10px 10px 25px 10px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 100%;
            background: #E0E0E0;
            border: 1px solid #BDBDBD;
            box-sizing: border-box;
            border-radius: 10px;
            text-decoration: none;
            color: $colorBase;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                background: url("../images/logo-player.svg") no-repeat;
                width: 50px;
                height: 15px;
                bottom: 5px;
                left: 50%;
                margin-left: -25px;
            }

        }

        &_full-title {
            max-width: 200px;
            margin-bottom: 9px;
            text-decoration: none;
            color: $colorBase;
            display: inline-block;
        }

        &_full-close {
            background: url("../images/close-player.svg");
            width: 14px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            z-index: 101;
        }

        &_preview-play {
            width: 30px;
            height: 30px;

            border-radius: 500px;
            background: $colorRed;
            position: relative;

            &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 0 5px 10px;
                border-color: transparent transparent transparent #ffffff;
                position: absolute;
                left: 50%;
                content: "";
                margin-left: -4px;
                top: 10px;
            }
        }
    }

    &__beauty {
        @include mBottom(40px, 80px, 375px, 1600px, 80px);
    }

    &__tg-button {
        background: #039BE5;
        display: flex;
        height: 47px;
        font-style: normal;
        font-weight: normal;

        @include fluidFontSize(14px, 18px, 375px, 1600px, 18px);
        line-height: 110%;
        font-family: $font-base;
        /* identical to box height, or 20px */
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        color: $colorWhite;
        max-width: 643px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
        border-radius: 20px;

        span {
            background: url("../images/tg-button.svg") no-repeat center left;
            display: flex;
            align-items: center;
            padding-left: 40px;

            height: 27px;
        }
    }


    &__item-del {
        @include max-width-mobile {
            display: none;
        }
        width: 23px;
        position: absolute;
        left: 3px;
        top: 0;
        z-index: 3;
        height: 23px;
        cursor: pointer;
        background: url("../images/del.svg") no-repeat;
    }

    &__item-done {
        @include max-width-mobile {
            display: none;
        }
        left: 3px;
        top: -12px;
        position: absolute;
        width: 23px;
        height: 23px;
        z-index: 3;
        cursor: pointer;
        background: url("../images/done.svg") no-repeat;
    }
}
