


//font
$font-base: 'Oswald', sans-serif;
$font-Roboto: 'Roboto', sans-serif;
$font-Noto-Serif: 'Noto Serif', serif;
$font-Extra-light: 200;
$font-Light: 300;
$font-Regular: 400;
$font-Medium: 500;
$font-Semi-bold: 600;
$font-Bold: 700;
// Base Color
$green: #6FCF97;
$green-bl: #27AE60;
$colorWhite: #fff;
$colorBase: #333333;
$colorGray: #828282;
$colorGraySoft: #BDBDBD;
$colorBlack: #000;
$colorGrayBorder: #E0E0E0;
$back-F2F2F2: #F2F2F2;
$colorRed: #ec1313;
$colorRedBold: #DC001D;
$wrapper: 1023.98px;
$container: 984px;
$mobile: 767px;
$mobile-phone: 400px;
$mobile-phone-lg: 600px;

@mixin max-width-mobile-phone {
    @media (max-width: #{$mobile-phone}) {
        @content;
    }
}

@mixin max-width-mobile-phone-lg {
    @media (max-width: #{$mobile-phone-lg}) {
        @content;
    }
}

@mixin max-width-mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

@mixin max-width-wrapper {
    @media (max-width: #{$wrapper}) {
        @content;
    }
}


@mixin portrait-laptop {
    @media(min-width: #{$mobile}) and (max-width: #{$container}) {
        @content;
    }
}


@mixin max-width-container {
    @media (max-width: #{$container}) {
        @content;
    }
}


@mixin min-width-container {
    @media (min-width: #{$container}) {
        @content;
    }
}
@use 'sass:math';

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1); // Используем оператор деления /
    }
    @return $number;
}
@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
    $f-min: strip-unit($f-min);
    $f-max: strip-unit($f-max);
    $w-min: strip-unit($w-min);
    $w-max: strip-unit($w-max);

    // Используем деление через оператор `/`
    $k: ($f-max - $f-min) / ($w-max - $w-min);
    $b: $f-min - $k * $w-min;

    $b: $b + $units;

    // Возвращаем результат через функцию calc()
    @return calc(#{$k} * 100vw + #{$b});
}
@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    font-size: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            font-size: $fallback;
        }
        font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        font-size: $f-max;
    }
}

@mixin fluidLineH($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    line-height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            line-height: $fallback;
        }
        line-height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        line-height: $f-max;
    }
}


@mixin absRight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    right: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            right: $fallback;
        }
        right: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        right: $f-max;
    }
}


@mixin gridGap($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    grid-gap: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            grid-gap: $fallback;
        }
        grid-gap: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        grid-gap: $f-max;
    }
}


@mixin absBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            bottom: $fallback;
        }
        bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        bottom: $f-max;
    }
}


@mixin minHeight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    min-height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            min-height: $fallback;
        }
        min-height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        min-height: $f-max;
    }
}


@mixin pBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-bottom: $fallback;
        }
        padding-bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-bottom: $f-max;
    }
}


@mixin pRight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-right: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-right: $fallback;
        }
        padding-right: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-right: $f-max;
    }
}


@mixin mBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-bottom: $fallback;
        }
        margin-bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-bottom: $f-max;
    }
}

@mixin mLeft($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-left: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-left: $fallback;
        }
        margin-left: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-left: $f-max;
    }
}


@mixin mRight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-right: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-right: $fallback;
        }
        margin-right: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-right: $f-max;
    }
}


@mixin mTop($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-top: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-top: $fallback;
        }
        margin-top: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-top: $f-max;
    }
}


@mixin pTop($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-top: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-top: $fallback;
        }
        padding-top: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-top: $f-max;
    }
}

@mixin Width($f-min, $f-max, $w-min, $w-max, $fallback: false) {
    width: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            width: $fallback;
        }
        width: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        width: $f-max;
    }
}


@mixin Height($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            height: $fallback;
        }
        height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        height: $f-max;
    }
}

/*li a:matches(:hover, :active) {
  color: red;
}*/
.banners-7days {
    .header-7days {
        margin-top: 136px;

        .header-7days__infopanel-sticky {
            top: 136px;
        }
    }
}

.wrapper-7days {
    max-width: 1024px;
    margin: 0 auto;
    width: 100%;
    font-weight: $font-Light;
    font-family: $font-base;
    color: $colorBase;
    box-sizing: border-box;
    background: $colorWhite;
    padding: 10px 10px 80px 10px;


    sup {
        font-size: 70%;
    }

    &__banners-brand-block {

        display: none;
    }

    @include max-width-container {
        overflow: hidden;
        padding-bottom: 30px;
        &__banners-brand-block {
            height: 150px;
            display: block;
            position: fixed;
            top: 0;
            width: 100%;
            margin-right: -10px;
            margin-left: -10px;
            z-index: 9998;
            box-sizing: border-box;


        }


        &.banners-brand {
            margin-top: 150px;

            .header-7days {
                padding-top: 0;
            }

            .header-7days__infopanel-sticky {
                top: 90px;
                width: 96%;
                left: 2%;
                right: 2%;
                z-index: 9999;
                border-radius: 10px 10px 0 0;
                box-sizing: border-box;
                box-shadow: none;
            }
        }
    }

    .none-mobile {
        display: block;
        @include max-width-container {
            display: none;
        }
    }

    .visible-mobile {
        display: none;
        @include max-width-container {
            display: block;
        }
    }
}

.container-7days {
    max-width: 984px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
   &.branding{
       max-width: 1000px;
       width: 100%;
       box-sizing: border-box;
       margin: 0 auto;
   }
    &__test {
        background: cornsilk;
        height: 2000px;
        margin-top: 35px;
        display: flex;
        @include fluidFontSize(20px, 60px, 375px, 1350px, 60px);
        align-items: center;
        text-align: center;

        span {
            width: 100%;
            box-sizing: border-box;
            display: block;
        }
    }

    hr {
        @include mBottom(30px, 40px, 375px, 1600px, 40px);
        @include mTop(30px, 40px, 375px, 1600px, 40px);
        height: 1px;
        width: 100%;
        background: #E0E0E0;

        &.transparent {
            background: transparent;
        }

    }
}

.content-7days {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: minmax(auto, 643px) 300px;
    grid-gap: 40px;
    margin-top: 20px;

    &.full {
        display: block;
        margin-top: 80px;
        @include max-width-container{
            margin-top: 40px;
        }
    }

    @include max-width-container {
        padding-left: 10px;
        grid-gap: 20px;
        display: block;
        padding-right: 10px;
    }
    @include max-width-mobile {
        display: block;
    }

    &__right-column {
        &_stars-trend {
            margin-top: 40px;
            border: 1px solid $colorGray;
            border-radius: 10px;

            box-sizing: border-box;

            .stars-form-search {

                align-items: center;
                display: grid;
                grid-template-columns: 1fr 40px;
                justify-content: space-between;
            }

            .stars-form-input {
                border: 1px solid $colorGray;
                border-radius: 20px;
                box-sizing: border-box;
                height: 40px;
                padding-left: 10px;
                padding-right: 10px;
                width: 100% !important;
            }


        }
    }

}

.breadcrumbs-7days {
    max-width: 984px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

    &__item {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        font-family: $font-Roboto;
        /* identical to box height, or 17px */
        text-decoration-line: underline;

        /* Gray 3 */
        color: $colorGray;
    }

    &__separator {
        margin-left: 10px;
        margin-right: 10px;
    }

    &.no-mrg {
        margin-top: 0;
    }
}


@function max($numbers...) {
    @return m#{a}x(#{$numbers})
}

@function min($numbers...) {
    @return m#{i}n(#{$numbers})
}


@mixin pLeft($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-left: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-left: $fallback;
        }
        padding-left: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-left: $f-max;
    }
}


@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

.autocomplete-search {
    &.autoComplete_wrapper {
        position: relative;
        display: block;

    }

    .autocomplete {
        height: 40px;

        margin: 0;
        padding: 0 20px 0 20px;
        box-sizing: border-box;
        font-size: 1rem;
        text-overflow: ellipsis;
        color: $colorBase;
        outline: none;
        border-radius: 10rem;
        border: 1px solid $colorGray;
        background: none;
        background-color: transparent;
        transition: all 0.4s ease;

        &::placeholder {
            color: $colorBase;
            transition: all 0.3s ease;
        }

        &:hover {
            color: $colorBase;
            transition: all 0.3s ease;

            &::placeholder {
                color: $colorBase;
                transition: all 0.3s ease;

            }
        }

        &:focus {
            color: $colorBase;
            border: 1px solid $colorGray;

            &::placeholder {
                padding: 0;
                font-size: 0.95rem;
                color: $colorBase;
            }

            &::selection {
                background-color: transparent;
            }


        }

        &::selection {
            background-color: rgba(255, 122, 122, 0.15);
        }

    }

    .autoComplete_list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 0;
        margin-top: 0.5rem;
        border-radius: 0.6rem;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
        border: 1px solid rgba(33, 33, 33, 0.07);
        z-index: 1000;
        outline: none;
    }

    .autoComplete_result {
        margin: 0.3rem;
        padding: 0.3rem 0.5rem;
        list-style: none;
        text-align: left;
        font-size: 1rem;
        color: #212121;
        border-radius: 0.35rem;
        background-color: rgba(255, 255, 255, 1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.2s ease;


        &:hover {
            cursor: pointer;
            background-color: rgba(255, 122, 122, 0.15);
        }

        &::selection {
            color: rgba(#ffffff, 0);
            background-color: rgba(#ffffff, 0);
        }


    }

    .autoComplete_highlighted {
        color: rgba(255, 122, 122, 1);
        font-weight: bold;

        &::selection {
            color: rgba(#ffffff, 0);
            background-color: rgba(#ffffff, 0);
        }

    }

    .autoComplete_selected {
        cursor: pointer;
        background-color: rgba(255, 122, 122, 0.15);
    }
}


@media only screen and (max-width: 600px) {
    .autocomplete-search .autoComplete_wrapper {
        width: 100%;
    }

    .autocomplete-search .autocomplete {
        width: 100%;
        background: none;
    }

    .autocomplete-search .autoComplete_list {
        margin: 10px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .autocomplete-search .autocomplete {
        border-width: 1px;
    }
}

@-moz-document url-prefix() {
    .autocomplete-search .autocomplete {
        background: none;
    }
}


.b-stripe {
    b, strong {
        font-weight: bold;
    }
}

.b-post-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: justify;
    text-justify: distribute-all-lines;
    font: 0/0 null, a;
    background: #cacaca;
    padding: 1px 13px;
    margin: 0 auto 24px;
}

.b-post-panel__info, .b-post-panel__title {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    text-transform: uppercase;
    color: #333;
    text-align: left;
    font-family: Ubuntu, Arial, Helvetica, sans-serif;
}

.b-post-panel__info, .b-post-panel__title {
    font-size: 13px;
    text-transform: uppercase;
    color: #333;
    text-align: left;
}


.b-post-panel__info, .b-post-panel__title {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    text-transform: uppercase;
    color: #333;
    text-align: left;
}

.b-icon__text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    padding-left: 3px;
    color: #333;
}

.b-page__in {
    cursor: default;
    cursor: auto;
}

.b-post-panel__item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 35px;
    text-decoration: none;
}

.b-icon {
    white-space: nowrap;
}

.b-ico {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-position: 0 -595px;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
    background-image: url(../images/b-ico.png);
    background-color: transparent;
}

.b-ico_content_post-views, .b-ico_content_story-views-small {
    width: 16px;
    height: 16px;
    background-position: 0 -544px;
    opacity: .7;
}

.webp .b-ico {
    background-image: url(../images/b-ico.webp);
}

///////////fotogallery old style


.b-photo-gallery {
    min-width: 590px;

    color: #bbb;
    background: #222;
    margin-right: 370px;
    margin-left: -480px;
    padding: 0;
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    left: 50%;
    z-index: 96;

    .pointerevents & {
        transform: translateZ(0)
    }

    &,
    & * {
        pointer-events: auto
    }

    &__holder {
        position: relative;
        height: 750px;
        background: #eee;
        clear: both;
    }

    &__wrap {
        background: #222;
        position: absolute;
        /*overflow: hidden;*/
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /*height:551px*/
        margin: auto;
        z-index: 2;
        padding: 40px 15px 155px;
    }

    &__photo-meta {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 12px 100px 0 15px;
        height: 28px;
        /*        overflow: hidden;*/
        white-space: nowrap;
    }

    &__title {
        color: #fff;
        font-size: 18px;
        text-decoration: none;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:after,
        & > .b-after {
            content: '';
            position: absolute;
            right: 0;
            width: 20px;
            height: 100%;
            background: linear-gradient(to right, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.5) 60%, rgba(34, 34, 34, 1) 100%)
        }


        .b-icon__text {
            padding-left: 3px;
            white-space: nowrap
        }
    }

    &__nav {
        position: absolute;
        top: 12px;
        right: 15px;
        line-height: 28px;
    }

    &__photo {
        position: absolute;
        top: 40px;
        left: 15px;
        right: 15px;
        bottom: 150px;
        margin: 0 auto;
        text-align: center;
        max-height: 500px;
    }

    &__photo-list {
        /*position:relative
        top:0
        left:0*/
        //width:100%
        height: 100%;
        overflow: hidden;
        width: 500px;;
        position: relative;
        margin: 0 auto;
    }

    &__photo-list-item {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        white-space: nowrap;
        transition: left 0.3s linear;

        &_type_previous {
            left: -100%
        }

        &_type_next {
            left: 100%
        }

        &_type_selected {
            left: 0
        }

        &:after,
        & > .b-after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 100%
        }

    }

    &__image {
        max-height: 100%;
        white-space: normal
    }

    &__photo-caption {
        position: absolute;
        top: 100%;
        left: 30px;
        color: #999;
        text-align: left;
        font: 10px/14px;
        margin-top: 15px;
    }

    &__thumbnails {
        position: absolute;
        top: auto;
        bottom: 20px;
        left: 15px;
        right: 15px;
        height: 104px;
        padding: 0;
        overflow: hidden;

        white-space: nowrap
    }

    &__thumbnails-list {
        display: block;
        position: relative;
        margin: 0 -4px;
    }

    &__thumbnails-item {
        display: inline-block;
        vertical-align: middle;
        padding: 4px;
        margin: 0 4px;
        cursor: pointer
    }

    &__thumbnails-item_state_selected {
        padding: 0;
        border: #59c4f0 solid 4px
    }

    &__info {
        position: absolute;
        z-index: 1;
        overflow: hidden;
        top: 0;
        bottom: 0;
        right: -370px;
        width: 330px;
        padding: 40px 20px 0;
        background: #333
    }

    &__action {
        position: absolute;
        top: 12px;
        right: 20px;
        color: #fff;


        .js & {
            cursor: pointer
        }

        .b-icon__text {
            padding-left: 3px
        }

        &_type_expand {
            display: block
        }

        &_type_close {
            display: none;
            right: 17px
        }
    }

    &_type_post {
        margin-right: 330px;

        .b-photo-gallery__info {
            width: 290px;
            right: 0;
            transition: right .3s ease-out
        }

        &:hover,
        &.b-photo-gallery_state_touched {
            /*box-shadow:0 0 10px #000*/

            .b-photo-gallery__info {
                right: -330px
            }
        }
    }

    &__page {
        display: none;
    }

    &_fullscreen {
        position: fixed;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        width: auto !important;
        height: auto !important;
        margin-left: 0;
        margin-right: 379px;
        z-index: 10000;
        background: none;

        .b-photo-gallery__wrap {
            height: auto;
            background: rgba(34, 34, 41, 0.98);
        }

        .b-photo-gallery__progress {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-flow: row nowrap;

            &-item {
                flex: 1 1 auto;
                height: 5px;
                background: #808080;

                &:not(:last-child) {
                    margin-right: 2px;
                }

                &_active {
                    background: #fff;
                }
            }
        }

        .b-photo-gallery__title,
        .b-photo-gallery__thumbnails,
        .b-photo-gallery__photo-caption {
            display: none;
        }

        .b-photo-gallery__photo {
            height: auto !important;
            bottom: 40px;
            max-height: 100%;
        }

        .b-photo-gallery__photo-list {
            width: 100%;
        }

        .b-pagination_type_gallery-photo .b-pagination__item-in {
            background: none
        }

        &.b-photo-gallery_type_post {
            .b-photo-gallery__info {
                width: 380px;
                right: -380px;
                border: 1px solid transparent;
                overflow-y: scroll;
            }
        }

        .b-photo-gallery__action {
            z-index: 1;

            &_type_expand {
                display: none
            }

            &_type_close {
                display: block;
                width: 39px;
                height: 39px;
                right: 30px;
                top: 30px;
                background: url('../images/photo-gallery-close.svg') no-repeat center center;
                background-size: 39px 39px;

                .b-ico {
                    display: none;
                }
            }
        }

        .b-photo-gallery__info {
            background: #fff;
            color: #26262d;
            padding-top: 20px;
        }

        .b-photo-gallery__photo-meta {
            height: 40px;
        }

        .b-photo-gallery__page {
            display: block;
            font-size: 24px;
            color: #fff;
            margin-top: 10px;

            &-img {
                width: 34px;
                height: 20px;
                margin-right: 6px;
            }

            &-text {
                vertical-align: middle
            }
        }
    }
}


.b-pagination_type_gallery-photo {
    margin: 0;
    padding: 0;

    .b-pagination__item {
        height: 100%;
        background: none;

        &_page_previous,
        &_page_next {
            width: 36px;
            background: url('../images/photo-gallery-left-arrow.svg') no-repeat -9px center;
            background-size: 39px 72px;
            border-radius: 0;
            margin: 0;
            position: absolute;
            top: 0;
            transition: none
        }

        &_page_previous {
            left: 0;

            .b-pagination__item-in {
                left: 0;
                display: none
            }
        }

        &_page_next {
            right: 0;
            background: url('../images/photo-gallery-right-arrow.svg') no-repeat 7px center;
            background-size: 39px 72px;

            .b-pagination__item-in {
                right: -1px;
                display: none
            }

            &:hover {

            }
        }
    }

    .b-pagination__item-in {
        position: absolute;
        top: 0;
        width: 15px;
        height: 100%;
        background: #222;

    }

    .b-pagination__item-ico {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -12px 0 0 -12px
    }
}

.b-post-links {
    &__text {
        color: inherit;
    }
}


/////// ico

.b-ico_content_previous-page {
    width: 5px;
    height: 7px;
}

.b-ico_content_next-page {
    width: 5px;
    height: 7px;
    background-position: 0 -247px;
}

.b-ico_content_previous-page:hover,
.b-icon:hover .b-ico_content_previous-page {
    background-position: -11px -238px;
}

.b-ico_content_next-page:hover,
.b-icon:hover .b-ico_content_next-page {
    background-position: -11px -247px;
}

.b-ico_content_story-views-small,
.b-ico_content_post-views {
    opacity: 0.7;
}

.b-ico_content_story-views-small__active,
.b-ico_content_post-views__active,
a.b-icon_state_active > .b-ico_content_story-views-small,
a.b-icon_state_active > .b-ico_content_post-views,
.b-ico_content_story-views-small:hover,
.b-ico_content_post-views:hover,
a:hover .b-ico_content_story-views-small,
a:hover .b-ico_content_post-views {
    opacity: 1;
}

.b-ico_content_story-comments-small,
.b-ico_content_comment,
.b-ico_content_post-comments {
    opacity: 0.7;
}

.b-ico_content_story-comments-small__active,
.b-ico_content_comment__active,
.b-ico_content_post-comments__active,
a.b-icon_state_active > .b-ico_content_story-comments-small,
a.b-icon_state_active > .b-ico_content_comment,
a.b-icon_state_active > .b-ico_content_post-comments,
.b-ico_content_story-comments-small:hover,
.b-ico_content_comment:hover,
.b-ico_content_post-comments:hover,
a:hover .b-ico_content_story-comments-small,
a:hover .b-ico_content_comment,
a:hover .b-ico_content_post-comments {
    opacity: 1;
}

.b-ico_content_mobile-version {
    margin-right: 5px;
}

.b-ico_content_extrasens-dislike {
    background-position: 0 -1427px;
}

.b-ico_content_story-comments {
    width: 17px;
    height: 16px;
}

.b-ico_content_story-comments:hover,
a:hover > .b-ico_content_story-comments,
.b-ico_content_story-comments-active {
    width: 17px;
    height: 16px;
}

.b-ico_content_app-store_small {
    width: 15px;
    height: 17px;
}

.b-ico_content_dbl-arrow-left {
    opacity: 0.5;
}

.b-ico_content_dbl-arrow-left__active,
a.b-icon_state_active > .b-ico_content_dbl-arrow-left,
.b-ico_content_dbl-arrow-left:hover,
a:hover .b-ico_content_dbl-arrow-left {
    opacity: 1;
}

.b-ico_content_dbl-arrow-right {
    opacity: 0.5;
}

.b-ico_content_dbl-arrow-right__active,
a.b-icon_state_active > .b-ico_content_dbl-arrow-right,
.b-ico_content_dbl-arrow-right:hover,
a:hover .b-ico_content_dbl-arrow-right {
    opacity: 1;
}

.b-ico_content_previous-page-red {
    background-position: -17px -1076px;
}

.b-ico_content_next-page-red {
    background-position: -17px -1101px;
}

.b-ico_content_video-story-small {
    width: 28px;
    height: 28px;
}

.b-ico_content_question-poll {
    width: 30px;
    height: 30px;
}

.b-ico_content_notice-warning {
    width: 29px;
    height: 29px;
}

.b-ico_content_auth-vkontakte-small {
    width: 32px;
    height: 32px;
}

.b-ico_content_auth-twitter-small {
    width: 32px;
    height: 32px;
}

.b-ico_content_auth-facebook-small {
    width: 32px;
    height: 32px;
}

.b-ico_content_auth-odnoklassniki-small {
    width: 32px;
    height: 32px;
}

.b-ico_content_mobile-story-comments {
    width: 33px;
    height: 32px;
}

.b-ico_content_pano-video {
    height: 21px;
}

.b-ico_content_google-play {
    width: 33px;
    height: 33px;
}

.b-ico_content_app-store {
    width: 33px;
    height: 33px;
}

.b-ico_content_facebook-share {
    height: 24px;
    width: 36px;
}

.b-ico_content_vkontakte-share {
    height: 24px;
    width: 36px;
}

.b-ico_content_mailru-share {
    height: 24px;
    width: 36px;
}

.b-ico_content_odnoklassniki-share {
    height: 24px;
    width: 36px;
}

.b-ico_content_twitter-share {
    height: 24px;
    width: 36px;
}

.b-ico_content_vkontakte-leonardo {
    height: 40px;
    width: 40px;
}

.b-ico_content_odnoklassniki-leonardo {
    height: 40px;
    width: 40px;
}

.b-ico_content_instagram-leonardo {
    height: 40px;
    width: 40px;
}

.b-ico_content_facebook-leonardo {
    height: 40px;
    width: 40px;
}

.b-ico_content_like {
    width: 51px;
    height: 51px;
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeOutRight {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.js-adaptive-block {
    width: 100%;
}

.m-decorated-block > .material-7days__content > *:last-child {
    margin-bottom: 0;
}
/*old anons*/
.b-preview-old-material {
    max-width: 500px;
    margin: 0 auto;
    font-family: Ubuntu, Arial, Helvetica, sans-serif;
    display: flex;
    gap: 20px;
    @media (max-width: 960px) {
        flex-direction: column;

    }

    &__logo {

        @media (max-width: 960px) {
            padding-top: 40px;
            border-top: 1px solid #D9D9D9;
        }
    }

    &__title {
        border-top: 1px solid #D9D9D9;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 120%;
        color: #000000;
        padding-top: 9px;
        text-decoration: none;
        padding-bottom: 12px !important;
        background: none !important;

        @media (max-width: 960px) {
            font-size: 20px;
            border-top: 0;
            padding-top: 0px;
            padding-bottom: 30px !important;
        }
    }

    &__button-box {
        border-top: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: right;


        &_button {
            background: #EE4A4B !important;
            border-radius: 4px;
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            margin-top: -12px;
            color: $colorWhite !important;
            padding: 1px 25px 3px 25px !important;
        }
    }
}


/*old anons*/
